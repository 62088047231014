.main-box {
  background-color: #ffff;
  box-shadow: none !important;
}
a{
  text-decoration: none !important;
}

.upper-box {
  background-color: #ffff;
}

.close-btn {
  color: #463d39 !important;
}

.nav-outer {
  justify-content: end !important;
}

.navigation li a {
  text-decoration: none;
}

.count {
  font-size: 30px !important;
}
.footer_logo_bg {
  width: fit-content;
  background-color: #ffff;
  padding: 15px;
  /* opacity: 0.5; */
  /* z-index: -1; */
}

.course-block{
  margin: 20px;
}
.about-section{
  padding: 0% !important;
}

/* .footer_logo{
  opacity: 1;
  z-index: 11;
} */

.call-to-action{
  margin-top: 100px;
}
.button-contact{
  margin: 10px;
}
.tparrows{
  display: none !important;
}

.iconstr{
  stroke-width: 1;
  padding: 20px;
}

.iconpadding{
  padding: 20px;
}

.align-center{
  text-align: start;
}


.text-justify{
  text-align: justify;
}

ul{
  margin: 0% ;
  padding: 0% !important;
}

/* .title{
  font-size: 20px;
  font-weight: bolder;
} */

.category-block-current-two:nth-child(6n+1) .inner-box .sub-title {
  color: #00c7c4;
}

.model_header{
  /* background-color: #00c7c4; */
  border: none;
  color: #1B2E47;
  font-family: "DM Sans", sans-serif;
}
.modal-title{
  font-weight: bold !important;
}
.model-form{
  border: 1px solid #00c7c4 !important;
}

.google_logo{
  width: 80px;
}

.p-left{
  padding-left: 5px !important;
  padding-right: 0 !important;
}
.p-right{
  padding-left: 0 !important;
  padding-right: 5px !important;
}


.accordion-item{
  border: none !important;
  border-radius: 25px !important;
}

.accordion{
  border-radius: 25px;
}

.accordion-button:not(.collapsed) {
  color: #00c7c4 !important;
  background-color: #ffff !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none !important;
}

.accordion-button{
  border-radius: 25px !important;
  font-size: 18px !important;
  line-height: 26px !important;
  font-weight: 700 !important;
}

@media only screen and (max-width: 768px) {
  .nav-outer {
    justify-content: end;
  }
  .logo-box {
    min-width: 0 !important;
  }
  .logo img {
    max-height: 100px !important;
  }
  .call-to-action{
    margin-top: 50px;
  }

  .text-details{
    padding-right: 100px;
  }

  .feature-title-one{
    padding-top: 30px;
  }
}

@media only screen and (max-width: 1440px) {
  .inner-column{
    margin-left: 0px !important;
  }
}


@media only screen and (max-width: 1024px) {
  .inner-column{
    margin-left: 0px !important;
  }
  .image-2{
    right: 0px !important;
  }
}


@media only screen and (max-width: 1124px) {
  .carousel-text{
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .main-slider .text {
    font-size: 18px;
    line-height: 30px;
    padding-top: 80px;
}

.slider_btn{
  margin-top: 70px;
}
}


@media only screen and (max-width: 2560px) {
  .tp-caption .title{
    font-size: 25px;
  }
  .main-slider .title:before{
    height: 30px;
  }

  .carousel-text{
    margin-top: 0px !important;
    padding-top: 10px;
    margin-bottom: 30px !important;
    font-size: 60px;
  }
}

.list_style li::marker{
    content: "🔹";
}
